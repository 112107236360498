body {
  background-color: #f8f8f8;
  h1 {
    font-size: 30px;
    line-height: 37px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  h3 {
    font-size: 17px;
    line-height: 25px;
  }
  h2 {
    color: #90969b;
    font-size: 16px;
    // font-weight: 400;
  }
  p {
    color: #90969b;
    font-size: 15px;
    margin: 16px 0 24px 0;
    line-height: 20px;
  }
  h6,
  a {
    padding: 16px;
    color: #90969b;
    font-size: 13px;
  }
  .authentication-wrapper {
    max-width: 440px;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    transition: all 0.5s ease;

    &.authentication-wrapper-476 {
      max-width: 476px;
    }
    &.not-ready {
      opacity: 0;
    }

    .authentication-outside-container {
      width: 100%;
      padding-top: 140px;
      text-align: center;
    }

    @media screen and (max-width: 640px) {
      .authentication-outside-container {
        padding-top: 5vh;
      }
    }

    .authentication-navigation {
      height: 40px;
      width: 100%;
      a {
        padding: 0 0 16px 0;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 14px;
      }
    }
    .authentication-container {
      width: 100%;
      background-color: white;
      padding: 16px;
      text-align: center;
      box-shadow: 2px 2px 4px 0 rgba(56, 58, 57, 0.08);
      box-sizing: border-box;

      .authentication-logo {
        // width: 152px;
        // margin: 24px auto 40px auto;
        // width: 240px;
        width: 174px;
        margin: 16px auto 32px auto;
        height: 60px;
        img {
          width: 100%;
        }
      }
      .MuiFormHelperText-root.Mui-error {
        margin: 0;
        padding: 4px 0 0 4px;
        position: absolute;
        top: 56px;
      }
    }
    .authentication-form {
      .MuiCircularProgress-root {
        margin-left: 10px;
      }
    }

    .authentication-footer {
      text-align: center;
      padding-bottom: 16px;
      padding-top: 16px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p,
      a {
        padding: 16px;
        color: #90969b;
        font-size: 13px;
      }
      .language-selector {
        padding: 16px;
        color: #90969b;
        font-size: 13px;
        cursor: pointer;
      }
      h6 {
        font-weight: normal;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .authentication-wrapper {
      width: auto;
      margin: 0 20px;
    }
  }
}

.password-tips {
  margin-top: 30px;
  font-size: 14px;
  line-height: 18px;
  color: #6a6e71;
  text-align: left;
}

.tooltip-smart {
  min-width: 390px;
}
.reset-email-info {
  color: #6a6e71;
  font-size: 14px;
  line-height: 20px;
  // text-align: left;
  display: block;
  padding-left: 12px;
  > p {
    color: #6a6e71;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 18px;
  }
  ul {
    margin-left: 30px;
    li {
      display: list-item;
      list-style: circle;
      margin-bottom: 10px;
      a {
        font-size: 14px;
        line-height: 20px;
        padding: 0;
        margin: 0;
        color: #c61806;
      }
    }
  }
}
