.download-app-wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .download-app-container {
    button,
    p,
    a {
      margin: 10px;
      width: 100%;
    }
  }
}
