.legal-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background: #fff;

  &-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 960px;
    height: 100%;
    margin: 0 auto;
    background: none;
    .page-header {
      height: auto;
      .page-header-inner {
        padding: 40px 0 0 0;
        position: relative;
      }
    }
  }

  &-content {
    flex-basis: 100%;
    padding: 0 32px 0 32px;
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 40px 32px 50px;

    > * {
      width: 100%;
    }
  }
}

@media screen and (max-width: 640px) {
  .legal-wrapper-container {
    .detail-header-container {
      padding: 0px 16px;
    }
    .legal-wrapper-content {
      padding: 0px 16px;
    }
    .legal-wrapper-footer {
      padding: 40px 20px 32px 20px;
      .MuiFormControlLabel-root {
        margin-bottom: 16px;
      }
    }
  }
}
