.auth-topbar {
  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    border-bottom: 1px solid #d8d8d8;
    background: #fff;

    .topbar-right {
      display: flex;
    }
  }

  &-logo {
    width: 34px;
    height: 34px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.identity-popover {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    .MuiSvgIcon-root {
      margin-right: 10px;
    }

    .MuiAvatar-root {
      width: 32px;
      height: 32px;
      margin-right: 10px;
    }
  }

  &-menu {
    .MuiMenu-list {
      padding: 0;
    }

    .menu-item {
      min-width: 140px;
      padding: 10px 0;

      > * {
        width: 100%;
        padding: 6px 16px;
      }
    }
  }
}
