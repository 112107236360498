.terms-policy {
  &-link {
    &:link,
    &:visited {
      padding: 0;
      color: #6a6e71;
      font-size: inherit;
      text-decoration: underline !important;
    }
    &:hover {
      text-decoration: none !important;
    }
    &:focus,
    &:focus-visible {
      outline-color: #6a6e71;
    }
  }
}
